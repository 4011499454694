import * as React from 'react';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { observer } from 'src/utils/mobx-react';
import { ScoreBoardWrapper } from 'src/domains/sportsbook/webview/components/scoreboards/basic/ScoreboardBasic.style';
import { ScoreboardNewVersion } from 'src/domains/layouts/webview/components/ScoreboardNewVersion';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

interface ScoreboardBasicPropsType {
    event: EventModel;
}

export const ScoreboardBasic = observer('ScoreboardBasic', ({ event }: ScoreboardBasicPropsType) => {
    const common = useCommon();
    const { config } = ConfigComponents.get(common);
    const { feedGroupId, sport } = event;

    if (config.scoreboard.basic === null || feedGroupId === null || feedGroupId === undefined) {
        return null;
    }

    for (const item of config.scoreboard.basic) {
        if (item === sport) {
            return (
                <ScoreBoardWrapper>
                    <ScoreboardNewVersion
                        key={feedGroupId}
                        groupId={feedGroupId}
                        //@ts-expect-error
                        sportId={sport}
                        isProdEnv={true}
                    />
                </ScoreBoardWrapper>
            );
        }
    }

    return null;
});
