import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface RaceSummaryRowType {
    isPriceBoost?: boolean;
}

export const SpanSelection = withConfig(theme => styled('span', { label: 'SpanSelection' })`
    background-color: ${theme.star.raceSummary.bgColor};
    border-left: 1px solid ${theme.star.raceSummary.borderColor};
    flex-shrink: 0;
    margin-left: -1px;
    &:last-of-type {
        border-right: 0;
    }
`);

export const RunnerDescription = withConfig(theme => styled('i', { label: 'RunnerDescription' })`
    color: ${theme.star.raceSummary.txtColorSecondary};
    display: block;
    font-size: ${theme.star.fontSize.xSmall};
    font-style: normal;
    line-height: 1.4;
`);

export const SelectionTag = withConfig(theme => styled('span', { label: 'SelectionTag' })<RaceSummaryRowType>`
    flex: 1 1 0%;
    padding: 0 8px;
    position: relative;
    z-index: ${theme.star.zIndexGlobal.above};
    border: 1px solid ${theme.star.priceBoost.bgColorTertiary};
    background-color: ${({ isPriceBoost }): string => isPriceBoost === true
        ? theme.star.priceBoost.bgColorSecondary
        : theme.star.priceBoost.bgColor};

    &:first-of-type {
        &::before {
            border-color: transparent transparent ${({ isPriceBoost }): string => isPriceBoost === true
                ? theme.star.priceBoost.bgColorSecondary
                : theme.star.priceBoost.bgColor} transparent;
            border-style: solid;
            border-width: 0 5px 7px 5px;
            bottom: 100%;
            content: '';
            height: 0;
            left: 34px;
            position: absolute;
            width: 0;
            @media ${theme.star.mediaQuery.tablet} {
                left: 45px;
            }
        }
    }
    background-color: ${({ isPriceBoost }): string => isPriceBoost === true ? `
        &:first-of-type {
            &::before {
                border-bottom-color: ${theme.star.priceBoost.bgColorSecondary};
            }
        }` : ''};
`);
