import * as React from 'react';
export interface MessageDataType {
    action: string;
    id: string;
    reason: 'declined' | 'referral-a' | 'referral-b' | 'comms-error';
    type: 'success' | 'error';
    error: string;
}
interface PropsType {
    name: string;
    title?: string;
    className?: string;
    src?: string;
    target?: string;
    onMessage?: (data: MessageDataType) => void;
    onLoad: () => void;
    dataTest?: string;
}

export class Iframe extends React.Component<PropsType> {
    private ref: HTMLIFrameElement | null = null;

    private onMessage = (event: MessageEvent): void => {
        if (this.ref !== null) {
            if (this.ref.contentWindow === event.source) {
                if (this.props.onMessage !== undefined) {
                    try {
                        const parsedData = JSON.parse(event.data);
                        this.props.onMessage(parsedData);
                    } catch (error) {
                        console.error('Failed to parse event data:', error);
                    }
                }
            }
        }
    };

    public componentDidMount(): void {
        window.addEventListener('message', this.onMessage);
    }

    public componentWillUnmount(): void {
        window.removeEventListener('message', this.onMessage);
    }

    public setRef = (ref: HTMLIFrameElement | null): void => {
        this.ref = ref;
    };

    public render(): JSX.Element {
        const { className, src, name, onLoad, title, dataTest } = this.props;

        return (
            <iframe
                className={className}
                name={name}
                src={src}
                ref={this.setRef}
                onLoad={onLoad}
                title={title}
                data-test={dataTest}
            />
        );
    }
}
