import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { TrpcClient } from 'src/appState/TrpcClient';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { useCommon } from 'src/domains/common/Common';
import {
    DragonBetLogo,
    OverlayDefault,
    Content,
    PopupHeader,
    PopupDescription,
    TermsBox,
    TermsBoxInner,
    BtnWrapper,
    AcceptBtn,
    LinkWrapper,
} from 'src/domains/players/webview/components/Account/BlockingNotifications/BlockingNotifications.style';
import { useAppStateContext } from 'src/appState/AppState';
import { Policy } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/Policy';

class BlockingNotificationsState {
    public constructor(
        private readonly trpcClient: TrpcClient,
        private readonly basicDataComp: BasicDataModel
    ) {}

    public onConfirm = async (): Promise<void> => {
        const confirmedNotification = { name: 'changeOfLicense', active: false };

        const allNotifications = this.basicDataComp.basicDataReady?.prompts?.notificationList ?? [];
        const updatedNnotifications = allNotifications.map((notification) =>
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            notification.name === 'changeOfLicense' ? confirmedNotification : notification
        );

        await this.trpcClient.client.accounts.approveNotification.mutate({
            prompts: { notificationList: updatedNnotifications },
        });
    };
}
export const BlockingNotifications = observer('DepositLimitPopup', (): JSX.Element | null => {
    const {
        config: { accountHelperMail },
    } = useAppStateContext();

    const common = useCommon();

    const basicDataComp = BasicDataModel.get(common);

    const [state] = useState(() => new BlockingNotificationsState(common.trpcClient, basicDataComp));

    return (
        <OverlayDefault data-test='overlay-prompt'>
            <Content>
                <DragonBetLogo />
                <PopupHeader>
                    <I18n
                        langKey='account.change-licence-notification.title'
                        defaultText='Change of Licence'
                    />
                </PopupHeader>
                <PopupDescription>
                    <I18n
                        langKey='account.change-licence-notification.description-part-1'
                        defaultText='DragonBet is now operating under the DragonBet Ltd Gambling Commission licence. To continue to use your account, please accept the new terms and conditions.'
                    />
                </PopupDescription>
                <TermsBox>
                    <TermsBoxInner>
                        <Policy id='terms-and-conditions' />
                    </TermsBoxInner>
                </TermsBox>
                <PopupDescription>
                    <I18n
                        langKey='account.change-licence-notification.description-part-2'
                        defaultText='Your account history, account balance, open bets and payment methods are unaffected. All your favourite DragonBet products are still available to bet on.'
                    />
                </PopupDescription>

                <PopupDescription>
                    <I18n
                        langKey='account.change-licence-notification.description-part-3'
                        defaultText='If you do not agree to have your account transferred to the DragonBet Ltd licence, do not click the Accept button and contact'
                    />
                    <LinkWrapper to={`mailto:${accountHelperMail}`}>
                        <I18n
                            langKey='account.deposit.info-page.email'
                            defaultText=' {accountHelperMail}'
                            params={{ accountHelperMail }}
                        />
                    </LinkWrapper>
                </PopupDescription>

                <BtnWrapper>
                    <AcceptBtn
                        onClick={state.onConfirm}
                        size='medium'
                    >
                        <I18n
                            langKey='account.change-license-notification.confirm-button.label'
                            defaultText='Accept'
                        />
                    </AcceptBtn>
                </BtnWrapper>
            </Content>
        </OverlayDefault>
    );
});
