import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';

export const PayButton = styled(Button, { label: 'PayButton' })`
    margin-top: 32px;
    text-transform: capitalize;
    width: 100%;
`;

export const ErrorLink = styled(Link, { label: 'ErrorLink' })`
    color: currentcolor;
`;
