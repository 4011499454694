import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

export const RaceBox = withConfig(theme => styled('section', { label: 'RaceBox' })`
    position: relative;
    border-style: solid;
    border-color: ${theme.star.heroWidget.borderColor};
    border-width: 0 0 1px 1px;
`);

export const ListWrapper = withConfig(theme => styled('ul', { label: 'ListWrapper' })<{autoHeightItem?: boolean}>`
    background-color: ${theme.star.heroWidget.bgColorTertiary};
    display: grid;
    @media ${theme.star.mediaQuery.desktop} {
        grid-template-columns: repeat(2, 50%);
    }
    li {
        border-bottom: 1px solid ${theme.star.heroWidget.borderColor};
        display: flex;
        min-height: 40px;
        height: ${({ autoHeightItem }): string => autoHeightItem === true ? 'auto' : '40px'};
        flex: 0 0 100%;
        &:last-child {
            border-bottom: 1px solid ${theme.star.heroWidget.borderColor};
        }

        @media ${theme.star.mediaQuery.desktop} {
            flex: 0 0 50%;
        }
    }
    .selection {
        height: 100%;
        border-right: 1px solid ${theme.star.heroWidget.borderColor};
    }
`);

export const SpecialEventsHeaderMaxbetText = withConfig(theme => styled('div', { label: 'SpecialEventsHeaderMaxbetText' })`
    font-size: 11px;
    font-weight: normal;
    color: ${theme.star.heroWidget.txtColor };
    line-height: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px;
`);

export const EventName = withConfig(theme => styled('span', { label: 'EventName' })`
    color: ${theme.star.heroWidget.txtColor };
    font-weight: ${theme.star.fontWeight.bold };
    position: relative;
    text-overflow: ellipsis;
    text-transform: uppercase;
    font-size: ${theme.star.fontSize.regular};
    line-height: 1.25;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.big};
    }
`);

export const EventInfo = withConfig(theme => styled('span', { label: 'EventInfo' })`
    display: block;
    color: ${theme.star.heroWidget.txtColor};
    font-size: ${theme.star.fontSize.xSmall };
    position: relative;
    text-overflow: ellipsis;
    line-height: 1.4;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.xRegular };
        line-height: 1.43;
    }
`);

export const ShowMoreWrapper = withConfig(theme => styled('div', { label: 'ShowMoreWrapper' })`
    align-items: center;
    background-color: ${theme.star.heroWidget.bgColorTertiary};
    border-right: 1px solid ${theme.star.heroWidget.borderColor};
    border-left: 1px solid ${theme.star.heroWidget.borderColor};
    color: ${theme.star.heroWidget.txtColorSecondary};
    cursor: pointer;
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    height: 40px;
    line-height: 1.3334;
    padding: 12px 0 12px 8px;
    text-decoration: none;
    text-transform: capitalize;
`);

export const GoToChevronIcon = styled(ChevronIcon, { label: 'GoToChevronIcon' })`
    width: 12px;
    fill: currentcolor;
    margin-left: 4px;
`;

export interface PromoBannersType {
    mobile: string | null;
    tablet: string | null;
    desktop: string | null;
    bgColor?: string | null;
}

export const heroImageStyles = (banners: PromoBannersType, theme: EmotionTheme): SerializedStyles => css`
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 96px;
    height: 96px;
    ${banners.mobile === null ? '' : `background-image: url(${banners.mobile})`};
    @media ${theme.star.mediaQuery.tablet} {
        ${banners.tablet === null ? '' : `background-image: url(${banners.tablet})`};
    }
    @media ${theme.star.mediaQuery.largeDesktop}{
        ${banners.desktop === null ? '' : `background-image: url(${banners.desktop})`};
    }
`;

export interface BannersType {
    banners: PromoBannersType;
    withMargin?: boolean;
    iaContent?: boolean;
}

export const WidgetBanner = withConfig(theme => styled('div', { label: 'WidgetBanner' })<BannersType>`
    background-color: ${({ banners }): string => banners.bgColor ?? theme.star.heroWidget.bgColor};
    ${({ banners }): SerializedStyles => heroImageStyles(banners, theme) };
    padding: 0 10px;
    cursor: ${({ iaContent }): string => iaContent === true ? 'pointer' :'default' };
`);

export const EventWrapper = styled('div', { label: 'EventWrapper' })`
    max-width: 270px;
    @media screen and (min-width: 420px) {
        max-width: none;
    }
`;

export const EventEachWay = withConfig(theme => styled('div', { label: 'EventEachWay' })`
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1;
    color: ${theme.star.heroWidget.txtColor};
    display: flex;
    
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.small};
        line-height: 1;
    }
`);

export const MarketGroupSection = withConfig(theme => styled('section', { label: 'MarketGroupSection' })`
    border-color: ${theme.star.heroWidget.borderColor};
    border-style: solid;
    border-width: 0 1px;
`);
