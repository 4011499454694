import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { BetslipCombination } from 'src/domains/sportsbook/betting/ui/betSlip/betslipCombination/BetslipCombination';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SelectionsList } from 'src/domains/sportsbook/betting/ui/betSlip/betslipMainContent/BetslipMainContent.style';
import {
    ChevronIconWrapper,
    HeaderWrapper,
    Label,
} from 'src/domains/sportsbook/betting/ui/betSlip/betslip/Betslip.style';
import { useAppStateContext } from 'src/appState/AppState';

export const BetslipMultiplesSection = observer('BetslipMultiplesSection', () => {
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();
    const { combinationsCast2, combinationWithoutCast2, basicBetSlipState } = betSlipState;
    const { isMultiplesOpenForView, onToggleMultiplesOpen } = basicBetSlipState;

    return combinationWithoutCast2.length > 1 && combinationsCast2.length === 0 ? (
        <SelectionsList data-test='betslip-multiples-section'>
            <HeaderWrapper onClick={onToggleMultiplesOpen}>
                <ChevronIconWrapper position={isMultiplesOpenForView ? 'down' : 'right'} />
                <Label data-test='betslip-section-header'>
                    <I18n
                        langKey='betslip.multiples'
                        defaultText='MULTIPLES'
                    />
                </Label>
            </HeaderWrapper>

            {combinationWithoutCast2.slice(1).map((combination) => {
                return (
                    <BetslipCombination
                        key={combination.reactKey}
                        selectionId={combination}
                        isHidden={!isMultiplesOpenForView}
                    />
                );
            })}
        </SelectionsList>
    ) : null;
});
