import React, { useState } from 'react';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { PaymentMethodDropdownState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/PaymentMethodDropdown.state';
import { WithdrawProcedure } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdraw/WithdrawProcedure';
import { WithdrawFormState } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/WithdrawFormState';
import { WithdrawIssue } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawIssue/WithdrawIssue';
import { PendingWithdrawals, WithdrawProcedureWrapper } from 'src/domains/players/webview/components/Account';
import { WithdrawSuccess } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawSuccess/WithdrawSuccess';
import { Amount } from 'src_common/common/amount/Amount';
import { useCommon } from 'src/domains/common/Common';

export type WithdrawStepsType =
    | {
          type: 'set-card';
      }
    | {
          type: 'failure-view';
          readonly failureType: WithdrawFailuresType;
      }
    | {
          type: 'withdraw-pending';
      };

export type WithdrawFailuresType = 'serverIssue' | 'noPaymentMethods';

const defaultWithdrawStep: WithdrawStepsType = {
    type: 'set-card',
};

export class WithdrawSteps {
    @observable.ref public step: WithdrawStepsType;

    public constructor(public initialStep?: WithdrawStepsType) {
        makeObservable(this);
        this.step = initialStep ?? defaultWithdrawStep;
    }

    @action public redirectToSetCard = (): void => {
        this.step = {
            type: 'set-card',
        };
    };

    @action public redirectToFailureView = (failureType: WithdrawFailuresType): void => {
        this.step = {
            type: 'failure-view',
            failureType,
        };
    };

    @action public redirectToPending = (): void => {
        this.step = {
            type: 'withdraw-pending',
        };
    };
}

export const WithdrawJourney = observer('WithdrawJourney', (): JSX.Element => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const {
        googleTagManager,
        languagesState,
        configComponents: {
            config: { minWithdrawAmount, usePaymentFormForUniverse },
            precision,
        },
    } = appLayoutsState;
    const { usersState, withdrawalsListState } = appPlayersState;
    const common = useCommon();

    const [paymentMethodDropdownState] = useState(
        () => new PaymentMethodDropdownState(common.trpcClient, usePaymentFormForUniverse)
    );
    const [state] = React.useState(
        () =>
            new WithdrawFormState(
                usersState,
                languagesState,
                withdrawalsListState,
                googleTagManager,
                paymentMethodDropdownState,
                new Amount(minWithdrawAmount),
                precision
            )
    );

    switch (state.currentStep.type) {
        case 'set-card':
            return (
                <>
                    <WithdrawProcedureWrapper data-test='withdraw-procedure-wrapper'>
                        <WithdrawProcedure
                            state={state}
                            paymentMethodDropdownState={paymentMethodDropdownState}
                        />
                    </WithdrawProcedureWrapper>
                    <PendingWithdrawals />
                </>
            );
        case 'failure-view':
            return <WithdrawIssue failureType={state.currentStep.failureType} />;
        case 'withdraw-pending':
            return <WithdrawSuccess />;
    }
});
